<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="手机号">
              <el-input v-model="phone" clearable></el-input>
            </el-form-item>
            <el-form-item label="用户昵称">
              <el-input v-model="nickname" clearable></el-input>
            </el-form-item>
            <el-form-item label="支付金额">
              <el-input v-model="amount" clearable></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="status" placeholder="请选择" clearable>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
            </el-form-item>
            <el-form-item>
             <el-button type="primary" @click="search">搜索</el-button>
            </el-form-item>
          </el-form>

      <el-table
        :data="payList"
        :fit="true"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="is_loading"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" class="expand-form">
              <el-form-item label="支付描述：">
                <span>{{ props.row.rcharge_abstract }}</span>
              </el-form-item>
              <el-form-item label="支付方式：">
                <span>{{ props.row.pay_type }}</span>
              </el-form-item>
              <el-form-item label="机构id：">
                <span>{{ props.row.mechanism_id }}</span>
              </el-form-item>
              <el-form-item label="交易来源：">
                <span v-if="props.row.source == 'sport'">小程序</span>
                <span v-else>{{ props.row.source }}</span>
              </el-form-item>
              <el-form-item label="订单类型：">
                <span>{{ props.row.type }}</span>
              </el-form-item>
              <el-form-item label="支付时间：">
                <span>{{ props.row.finished_time }}</span>
              </el-form-item>
              <el-form-item label="用户id：">
                <span>{{ props.row.user_id }}</span>
              </el-form-item>
              <el-form-item label="联系电话：">
                <span>{{ props.row.mobile }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="流水号" prop="flowing_no"></el-table-column>
        <el-table-column
          label="用户昵称"
          prop="map.userinfo.nick_name"
        ></el-table-column>
        <el-table-column label="金额" prop="amount"></el-table-column>
        <el-table-column
          label="下单时间"
          prop="finished_time"
        ></el-table-column>
        <!-- <el-table-column label="支付方式" prop="pay_type"></el-table-column> -->
        <el-table-column
          label="支付描述"
          prop="rcharge_abstract"
        ></el-table-column>
        <el-table-column label="支付状态">
          <template slot-scope="{ row }">
            <div v-if="row.finished" style="color: green">已支付</div>
            <div v-else>未支付</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="payTotal"
      ></paging-fy>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "财务管理中心", secondNav: "流水记录" },
      mechanism_id: null,
      mechanism_list: [],
      payList: [],
      status: null,
      is_loading: true,
      refuseReason: false,
      showReason: false,
      refuse_contect: "",
      is_loading: false,
      currentPage: 1,
      payTotal: 0,
      temp: {},
      status: "true",
      options: [
        {
          value: "true",
          label: "已支付",
        },
        {
          value: "false",
          label: "未支付",
        },
      ],
      phone: "",
      amount: "",
      nickname: "",
    };
  },
  mounted() {
    this.getRefoundList();
  },
  methods: {
    getRefoundList() {
      this.is_loading = true;
      let url = "/user/rechargerecord/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            finished: this.status,
            pageSize: 10,
            currentPage: this.currentPage,
            nick_name: this.nickname || "",
            login_name: this.phone || "",
            amount: this.amount || "",
          },
        })
        .then((res) => {
          this.payList = res.data.data.rows;
          this.payTotal = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getRefoundList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getRefoundList();
    },
    refuse(row) {
      this.temp = row;
      this.refuseReason = true;
    },
    showRefuse(row) {
      console.log(row);
      this.temp = row;
      this.showReason = true;
    },
    getMechanismList(query) {
      let url = "/user/mastermechanism/queryMechanismByType";
      this.$axios
        .get(url, {
          params: {
            // type: 'teach_paypal',
            categories: "体育运动",
            source: "体育宝",
            mechanism_name:
              typeof query == "string" && query != "" ? query : null,
            pageSize: 99,
            currentPage: 1,
          },
        })
        .then((res) => {
          // console.log(res);
          this.mechanism_list = res.data.data;
        });
    },
    update(id, status) {
      console.log(this.temp);
      let url = "/user/rechargerecordrefund/updateRefund";
      this.$axios
        .post(url, {
          id: id,
          status: status,
          refuse_contect: this.refuse_contect ? this.refuse_contect : null,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.getRefoundList();
            this.refuseReason = false;
          } else {
            this.$message({ type: "warning", message: res.data.message });
          }
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.refuseReason = false;
          this.showReason = false;
          this.temp = {};
          done();
        })
        .catch((_) => {});
    },
  },
  watch: {
    mechanism_id(newV, oldV) {
      if (newV) {
        this.getRefoundList();
      }
    },
  },
};
</script>

<style lang="less">
.expand-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;
  .el-form-item {
    width: 40%;
  }
}
</style>
